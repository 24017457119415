import React from 'react';
import Layout from '../components/Layout';
import { Link, graphql } from 'gatsby';
import church from '../img/church.jpg';
import sanctuary from '../img/sanctuary.jpg';
import PreviewCompatibleImage, { ImageInfo } from '../components/PreviewCompatibleImage';

export interface HomePageProps {
  intro: string;
  services: string;
  images: {
    church: ImageInfo;
    sanctuary: ImageInfo;
  };
}

export const HomePageTemplate: React.SFC<HomePageProps> = (props) => {
  return (
    <>
      <div className="hero is-medium">
        <div className="hero-body">
          <div className="columns is-vcentered">
            <div className="column is-3 is-offset-1">
              <p className="subtitle">{props.intro}</p>
              <Link to="about">LEARN MORE</Link>
            </div>
            <div className="column is-6 is-offset-1">
              <PreviewCompatibleImage imageInfo={props.images.church} />
            </div>
          </div>
        </div>
      </div>
      <div className="hero is-medium">
        <div className="hero-body">
          <div className="columns is-vcentered">
            <div className="column is-6 is-offset-1">
              <PreviewCompatibleImage imageInfo={props.images.sanctuary} />
            </div>
            <div className="column is-3 is-offset-1">
              <p className="subtitle">Services</p>
              <p className="is-2 mb-2">{props.services}</p>
              <p>
                <Link to="about#map">Location</Link>
              </p>

              <p>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/groups/194357744008279/"
                >
                  Video Streaming
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const HomePage = ({ data }) => {
  const { post, church, sanctuary } = data;

  const images = {
    church,
    sanctuary,
  };

  return (
    <Layout>
      <HomePageTemplate
        intro={post.frontmatter.intro}
        services={post.frontmatter.services}
        images={images}
      />
    </Layout>
  );
};

export const homePageQuery = graphql`
  query homePage($id: String!) {
    post: markdownRemark(id: { eq: $id }) {
      frontmatter {
        intro
        services
      }
    }
    church: file(relativePath: { eq: "church.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1052, quality: 92) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sanctuary: file(relativePath: { eq: "sanctuary.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1052, quality: 92) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default HomePage;
